import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Learnmore from "../components/learnmore"
import IndependentContent from "../components/independent/independentContent"
import ScrollReveal from 'scrollreveal'


const IndependentSites = () => {
  React.useEffect(()=> {
    ScrollReveal().reveal('.independent-foo',
      {
        delay: 0,
        duration: 400,
        distance: '80px',
        easing: 'ease-in-out',
        origin: "bottom",
        interval: 50,
        opacity : 0, 
        mobile: false,
        reset: false,
        init: false
      }
    )
  })
  return(
  <Layout pageInfo={{ pageName: "independent" }}>
    <SEO title="Independent Station" keywords={[`e-commerce`, `independent`, `shopping`]} />
    <div 
      className="independent-bg"
    >
      <span className="inde-bg-1 independent-foo"></span>
      <span className="inde-bg-2 independent-foo"></span>
      <span className="inde-bg-3 independent-foo"></span>
      <span className="inde-bg-4 independent-foo"></span>
      <div className="inde-bg-text">
        <div className="title independent-foo">Customized all-round services of independent station brands going overseas</div>
        <div className="text independent-foo">Professional technical and operational teams provide full-process services to help you successfully complete any section of brand independent station construction and maximize the benefits of your brand.</div>
      </div>
    </div>

    <IndependentContent />
    {/* 二级页脚 */}
    {/* <Learnmore /> */}
  </Layout>
)}

export default IndependentSites
