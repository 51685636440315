import React from "react"

const IndependentContent = () => {

    return (
    <div className="independent">
      <span className="inde-temp1 independent-foo"></span>
      <span className="inde-temp2 independent-foo"></span>
      <span className="inde-temp3 independent-foo"></span>
      <span className="inde-temp4 independent-foo"></span>
      <span className="inde-temp5 independent-foo"></span>

      <div className="title independent-foo">How to do a good job of brand independent station going overseas</div>
      <div className="inde-top">
        <div className="inde-item independent-foo">
          <svg className="icon independent-foo">
            <use href="#icondesgin" />
          </svg>
          <div className="title independent-foo">Homepage design</div>
          <div className="text independent-foo">The brand website represents the image of the enterprise to a certain extent. Through the exquisite website design, it can visually satisfy the customers.</div>
        </div>

        <div className="inde-item independent-foo">
          <svg className="icon independent-foo">
            <use href="#iconzhuanzhu" />
          </svg>
          <div className="title independent-foo">Focus on products</div>
          <div className="text independent-foo">Focus on your own brand products and bring more business opportunities to the enterprise with excellent products.</div>
        </div>

        <div className="inde-item independent-foo">
          <svg className="icon independent-foo">
            <use href="#iconplugin" />
          </svg>
          <div className="title independent-foo">Application plugin</div>
          <div className="text independent-foo">With the help of a variety of on-site marketing applications, improve product maintenance efficiency, improve user experience, and increase product conversion rate.</div>
        </div>
      </div>

      {/* 下 */}
      <div className="title independent-foo">Brand Design and Strategy</div>
      <div className="inde-bottom">
        <div className="inde-item independent-foo" style={{background: "#FFF4DE"}} data-sal="flip-right" data-sal-delay="600" >
          <div className="inde-item-in">
            <svg className="icon independent-foo">
              <use href="#iconOverseasbrandstrat" />
            </svg>
            <div className="title independent-foo">Overseas market analysis</div>
            <div className="text independent-foo">According to product characteristics, conduct survey and analysis of overseas target customers, and locate target users accurately.</div>
          </div>
        </div>

        <div className="inde-item independent-foo" style={{background: "#EDF1FF"}} data-sal="flip-right" data-sal-delay="300" >
          <div className="inde-item-in">
            <svg className="icon independent-foo">
              <use href="#iconOverseasmarketanal" />
            </svg>
            <div className="title independent-foo">Overseas brand strategy</div>
            <div className="text independent-foo">Through interviews and surveys, refine trends of overseas markets and consumers, confirm product selling points and core concepts.</div>
          </div>
        </div>

        <div className="inde-item independent-foo" style={{background: "#FFEBEC"}} data-sal="flip-right" data-sal-delay="900" >
          <div className="inde-item-in">
            <svg className="icon independent-foo">
              <use href="#iconBrandimagebuilding" />
            </svg>
            <div className="title independent-foo">Brand image building</div>
            <div className="text independent-foo">Based on consumer research, clarify the overall case planning of visual style, logo design, brand promotion and so on.</div>
          </div>
        </div>
      </div>
    </div>
)
    }
export default IndependentContent
